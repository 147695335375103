import {IDailyStrategyPNLReport} from "../../../apis/vitusApiTypes";
import moment from "moment";
import ReactECharts from "echarts-for-react";
import React from "react";
import {currencyFormatter, IGroupedDailyStrategyPNLReport} from "./utils";

const kFormatter = (value: number): string => {
    const absValue = Math.abs(value);
    if (absValue >= 1000000) {
        return `${(value / 1000000).toFixed(1)}M`;
    }
    if (absValue >= 1000) {
        return `${(value / 1000)}k`;
    }
    return value.toFixed(0);
};

export default function DailyChart({data: rawData, symbol = '', feesIncluded = false}: {
    data: (IDailyStrategyPNLReport | IGroupedDailyStrategyPNLReport)[],
    symbol?: string,
    feesIncluded?: boolean
}) {
    const data = [...rawData].sort((a, b) => moment(a.Date).valueOf() - moment(b.Date).valueOf())

    const options = {
        title: {
            text: symbol,
            left: 'center',
        },
        tooltip: {
            trigger: 'axis',
            formatter: function (params: any) {
                let result = params[0].axisValueLabel + '<br/>';
                params.forEach((param: any) => {
                    result += param.seriesName + ` ${currencyFormatter.format(param.value)}<br/>`;
                });
                return result;
            }
        },
        xAxis: {
            type: 'category',
            data: data.map((d) => moment(d.Date).format('DD-MM-YYYY'))
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: (value: number) => kFormatter(value)
            }
        },
        grid: {
            left: 10,     // Space for y-axis labels
            containLabel: true  // Ensure labels are included in the sizing
        },
        series: [
            {
                data: data.map((d, index) => {
                    const value = feesIncluded ? d.PnLTotalNet : d.PnLTotal;
                    return {
                        value: value,
                        label: {
                            show: index === data.length - 1,
                            formatter: (params: any) => currencyFormatter.format(params.value),
                            position: 'top'
                        }
                    };
                }),
                type: 'line',
                name: 'PnL Total',
                lineStyle: {
                    width: 3
                }
            },
            {
                data: data.map((d) => feesIncluded ? d.PnLDailyNet : d.PnLDaily),
                type: 'line',
                name: 'PnL Daily',
                lineStyle: {
                    type: "dashed",
                },
            },
            {
                data: data.map((d) => feesIncluded ? d.PnLCarryDailyNet : d.PnlCarryDaily),
                type: 'line',
                name: 'PnL Carry Daily',
                lineStyle: {
                    type: "dotted",
                },
            },
        ],
        legend: {
            orient: 'horizontal',
            left: 'center',
            top: 'bottom'
        },
    };

    return <ReactECharts option={options} style={{height: 400}}/>;
}
