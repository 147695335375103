import { Button, Grid, GridSize, Icon, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { IGlobalSettings } from '../../store/reducers/globalSettingsReducer';
import { GlobalStates } from '../../store/store';
import { updateRecentReports } from '../../store/actions';
import VTimer from '../../components/VTimer/VTimer';


interface IProps {
    dontChangeView?: boolean,
    children: React.ReactElement,
    name: string,
    path: string,
    cleanPath?: string,
    thumbnail: string,
    reportKey: string,
    settings: IGlobalSettings,
    userName?: string,
    shortName?: string,
    updateRecentReports: typeof updateRecentReports,
    onRefresh?: () => void,
    refreshButtonLabel?: string,
    autoRefreshMinutes?: number,
    lastRefresh?: Date,
    lastRefreshResult?: boolean,
    hideTitle?: boolean,
    hideTopButton?: boolean,
}

class ReportViewer extends React.Component<IProps, {}> {
    componentDidMount() {
        this.props.updateRecentReports({
            name: this.props.name,
            path: this.props.path,
            cleanPath: this.props.cleanPath,
            thumbnail: this.props.thumbnail,
            reportKey: this.props.reportKey,
            userName: this.props.userName || "",
            shortName: this.props.shortName,
        });
    }

    render() {
        let gridWidth = 12;
        if (this.props.onRefresh) gridWidth--;
        if (this.props.autoRefreshMinutes) gridWidth -= 2;

        return (
            this.props.dontChangeView ?
                <>
                    {this.props.children}
                </> :
                <div className="reportWrapper" style={{ width: "auto", height: "auto" }}>
                    <Grid container>
                        <Grid item xs={gridWidth as GridSize}>
                            {
                                !this.props.hideTitle &&
                                <Typography gutterBottom variant="h6">
                                    {this.props.name}
                                </Typography>
                            }
                        </Grid>
                        {
                            (this.props.autoRefreshMinutes && this.props.onRefresh) &&
                            <Grid item xs={2} style={{ textAlign: 'right' }}>
                                <VTimer
                                    timerEvent={this.props.onRefresh}
                                    lastRefresh={this.props.lastRefresh}
                                    lastRefreshResult={this.props.lastRefreshResult}
                                    label="Auto Refresh in"
                                    minutes={this.props.autoRefreshMinutes} />
                            </Grid>
                        }
                        {
                            this.props.onRefresh &&
                            <Grid item xs={1} style={{ textAlign: 'right', alignContent: "center" }}>
                                <Button variant="outlined" color="primary" size="small" onClick={() => this.props.onRefresh?.()}>
                                    <Icon style={{ marginRight: 5 }} className="fas fa-sync-alt" />
                                    {this.props.refreshButtonLabel ?? 'Refresh Data'}
                                </Button>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            {this.props.children}
                        </Grid>
                    </Grid>
                    {
                        !this.props.hideTopButton &&
                        <div style={{ position: "fixed", bottom: "10px", right: "5px" }}>
                            <IconButton style={{ maxHeight: "48px", maxWidth: "48px", opacity: "0.5", padding: 0 }}
                                title='Scroll to top'
                                onClick={() => {
                                    //https://www.w3schools.com/howto/howto_js_scroll_to_top.asp

                                    document.body.scrollTop = 0; // For Safari
                                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                                }}>
                                <Icon className="fa fa-arrow-up fa-fw" />
                                Top
                            </IconButton>
                        </div>
                    }
                </div >
        );
    };
}

const mapStateToProps = (state: GlobalStates) => {
    return {
        settings: state.settings,
        userName: state.auth.userName,
    };
};

export default connect(mapStateToProps, { updateRecentReports })(ReportViewer);
