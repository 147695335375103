import {ClassNameMap} from "@material-ui/styles";
import {Card, CardContent, Grid, makeStyles, Typography} from "@material-ui/core";
import React from "react";
import {currencyFormatter, formatDate, IGroupedDailyStrategyPNLReport, percentageFormatter} from "./utils";
import {IDailyStrategyPNLReportWithChanges} from "./DailyStrategyPNLReport";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600,
        height: '100%',
        margin: 'auto',
        cursor: 'pointer',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.1rem',
    },
    pos: {
        marginBottom: 12,
    },
    gridItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
    label: {
        fontWeight: 'bold',
    },
    value: {
        color: theme.palette.text.secondary,
    },
    positive: {
        color: theme.palette.success.main,
    },
    negative: {
        color: theme.palette.error.main,
    },
}));

export default function LastDaySummaryCard({maxDateData, symbol = '', showDate = true, showLastDate = false, expired = false, onClick}: {
    maxDateData: IDailyStrategyPNLReportWithChanges | IGroupedDailyStrategyPNLReport,
    symbol?: string,
    showDate?: boolean,
    showLastDate?: boolean;
    expired?: boolean;
    onClick?: (contract: IDailyStrategyPNLReportWithChanges) => void
}) {
    const classes = useStyles();

    function getTitle() {
        let title = `${symbol}`;

        if (showDate && maxDateData) {
            title += ` — ${formatDate(new Date(maxDateData.Date))}`;
        }

        if (expired) {
            title += ` (Expired)`
        }

        return title
    }

    return (
        <Card
            className={classes.root}
            style={{backgroundColor: expired ? '#e0e0e0' : 'inherit'}}
            onClick={() => onClick ? onClick(maxDateData as IDailyStrategyPNLReportWithChanges) : null}
        >
            <CardContent>
                <Typography className={classes.title} color="textPrimary" gutterBottom>
                    {getTitle()}
                </Typography>
                {showLastDate ? <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                    Latest Trade Date: {formatDate(new Date(maxDateData.Date))}
                </Typography> : null}
                <Grid container>
                    <DataItem currency label="PnL Total" value={maxDateData.PnLTotal} classes={classes} bold/>
                    <DataItem currency label="PnL Total (Net)" value={maxDateData.PnLTotalNet} classes={classes} bold/>
                    <DataItem percentage label="PnL Daily Change %" value={maxDateData.PnlDailyChangePercent}
                              classes={classes} bold/>
                    <DataItem percentage label="PnL Daily Change % (Net)" value={maxDateData.PnlNetDailyChangePercent}
                              classes={classes} bold/>
                    <DataItem currency label="PnL Daily" value={maxDateData.PnLDaily} classes={classes} bold/>
                    <DataItem currency label="PnL Daily (Net)" value={maxDateData.PnLDailyNet} classes={classes} bold/>
                    <DataItem currency label="PnL Carry Daily" value={maxDateData.PnlCarryDaily} classes={classes}
                              bold/>
                    <DataItem currency label="PnL Carry Daily (Net)" value={maxDateData.PnLCarryDailyNet}
                              classes={classes} bold/>
                    {maxDateData.LongDaily ? <DataItem label="Long Daily" value={maxDateData.LongDaily} classes={classes}/> : null}
                    {maxDateData.ShortDaily ? <DataItem label="Short Daily" value={maxDateData.ShortDaily} classes={classes}/> : null}
                    {maxDateData.LongTotal ? <DataItem label="Long Total" value={maxDateData.LongTotal} classes={classes}/> : null}
                    {maxDateData.ShortTotal ? <DataItem label="Short Total" value={maxDateData.ShortTotal} classes={classes}/> : null}
                    {!!maxDateData.LongTotal && !!maxDateData.ShortTotal ? <DataItem label="Net Position" value={maxDateData.LongTotal - maxDateData.ShortTotal} classes={classes}/> : null}
                </Grid>
            </CardContent>
        </Card>
    );
};

const DataItem = ({label, value, classes, bold = false, currency = false, percentage = false}: {
    label: string,
    value: number,
    classes: ClassNameMap,
    bold?: boolean,
    currency?: boolean,
    percentage?: boolean
}) => (
    <Grid item xs={12} className={classes.gridItem}>
        <Typography variant={bold ? 'h6' : 'body1'} className={classes.label}>{label}:</Typography>
        <Typography
            variant={bold ? 'h6' : 'body1'}
            className={getClass(classes, value, currency, percentage)}
        >
            {getFormattedValue(value, currency, percentage)}
        </Typography>
    </Grid>
);

function getClass(classes: ClassNameMap, value: number, currency: boolean, percentage: boolean) {
    if (currency || percentage) {
        return `${classes.value} ${value >= 0 ? classes.positive : classes.negative}`
    }

    return classes.value
}

function getFormattedValue(value: number, currency: boolean, percentage: boolean) {
    if (currency) return currencyFormatter.format(value);
    if (percentage) return percentageFormatter.format(value);
    return value
}
