import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { stickyColumnStyle } from '../../utils/styles';

export type TableData = { columns: string[], data: { [key: string]: any }[] };

interface IState {
    shownRowCount: number,
    tableTooltip?: { rowValue: string, colValue: string, cellValue: string },
    tooltipPosition?: { x?: number, y?: number },
}

interface IProps {
    tableData: TableData,
    containerStyle?: CSSProperties,
    cellStyle: CSSProperties,
    defaultInitialRowCount: number,
    makeFirstColumnsSticky: boolean,
    keyFields?: string[],
    striped?: boolean;
    borderedColumns?: Array<string | number>
    backgroundColors?: Map<string, string>
}

class VTable extends React.Component<IProps, IState>{
    public static defaultProps: Partial<IProps> = {
        defaultInitialRowCount: 72,
        makeFirstColumnsSticky: false,
        cellStyle: { width: 70, minWidth: 70, padding: 6 },
        striped: false,
    };

    state: IState = {
        shownRowCount: 0,
    }

    initializeComponent() {
        const data = this.props?.tableData?.data;

        if (data && this.props.defaultInitialRowCount > data.length)
            this.setState({ shownRowCount: data.length })
        else
            this.setState({ shownRowCount: this.props.defaultInitialRowCount })
    }

    componentDidMount() {
        this.initializeComponent();
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.tableData !== this.props.tableData)
            this.initializeComponent();
    }

    tableInProcess: boolean = false;
    tableCache: React.ReactElement | null = null;
    tableOwner: IProps["tableData"] | null = null;
    renderedRowCount: IState["shownRowCount"] | null = null;
    getTable(data: TableData['data']) {
        if (this.tableCache
            && this.tableOwner
            && this.tableOwner === this.props.tableData
            && this.renderedRowCount === this.state.shownRowCount)
            return this.tableCache;

        this.tableInProcess = true;

        const shownRowCount = this.state.shownRowCount;
        const defaultInitialRowCount = this.props.defaultInitialRowCount;
        const totalRowCount = data.length;

        this.tableOwner = this.props.tableData;
        this.renderedRowCount = this.state.shownRowCount;

        this.tableCache = (
            <Paper style={{ width: '100%', height: '100%', overflow: 'scroll' }}
                onScroll={(e) => {
                    if (this.tableInProcess || (this.renderedRowCount || 0) >= totalRowCount)
                        return;

                    const element = e.currentTarget;
                    if (element.scrollHeight - element.scrollTop < element.clientHeight * 1.5)
                        this.setState({ shownRowCount: shownRowCount + defaultInitialRowCount })
                }}>
                <TableContainer style={{ width: '100%', overflow: 'unset' }}>
                    <Table stickyHeader size="small" style={{ width: '100%' }}>
                        {this.getTableHead()}
                        {this.getTableBody(data)}
                    </Table>
                </TableContainer>
                <div
                    style={{ ...stickyColumnStyle, height: 30, width: '100%', textAlign: 'center' }}>
                    <Typography>
                        {this.renderedRowCount < totalRowCount ? this.renderedRowCount : totalRowCount} of {totalRowCount} rows are listed
                    </Typography>
                    {
                        this.renderedRowCount < totalRowCount &&
                        <CircularProgress disableShrink size={10} style={{ marginLeft: 10, }} />
                    }
                </div>

            </Paper >
        );

        this.tableInProcess = false;

        return this.tableCache;
    }

    getTableHead() {
        const { makeFirstColumnsSticky, cellStyle, borderedColumns } = this.props;
        const columns = this.props.tableData.columns;

        if (makeFirstColumnsSticky)
            return (
                <TableHead>
                    <TableRow>
                        <TableCell style={{
                            ...stickyColumnStyle, zIndex: 1001, ...cellStyle
                        }} key={`th_${columns[0]}`}>
                            {columns[0]}
                        </TableCell>
                        {columns.slice(1).map(column => (
                            <TableCell key={`tc_${column}`} style={cellStyle} >
                                {column}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
            );

        return (
            <TableHead>
                <TableRow>
                    {columns.map(((column, columnIndex) => (
                        <TableCell key={`tc_${column}`}
                                   style={{
                                       ...cellStyle,
                                       ...((borderedColumns?.includes(columnIndex) || borderedColumns?.includes(column)) ? { borderRight: '1px dashed black' } : {}),
                                   }}
                        >
                            {column}
                        </TableCell>
                    )))}
                </TableRow>
            </TableHead>
        )
    }

    getTableBody(data: TableData['data']) {
        const columns = this.props.tableData.columns;
        const { makeFirstColumnsSticky, cellStyle, keyFields, borderedColumns, backgroundColors } = this.props;

        return (
            <TableBody>
                {
                    data.slice(0, this.state.shownRowCount).map((row, index) => {
                        return (
                            <TableRow
                                key={keyFields ? keyFields.map(k => row[k]).join("_") : row[columns[0]]}
                                style={{
                                    backgroundColor: this.props.striped && index % 2 === 1 ? '#f5f5f5' : 'inherit',
                                }}
                            >
                                {
                                    makeFirstColumnsSticky ?
                                        <TableCell style={{
                                            ...stickyColumnStyle, zIndex: 1000, ...cellStyle
                                        }} key={`tc_${row[columns[0]]}_${columns[0]}`}>
                                            {row[columns[0]]?.toLocaleString()}
                                        </TableCell>
                                        : null
                                }

                                {columns.slice(makeFirstColumnsSticky ? 1 : 0).map((column, columnIndex) => {
                                    return (
                                        <TableCell
                                            key={`tc_${row[columns[0]]}_${column}`}
                                            style={{
                                                ...cellStyle,
                                                ...((borderedColumns?.includes(columnIndex) || borderedColumns?.includes(column)) ? { borderRight: '1px dashed black' } : {}),
                                                ...(backgroundColors?.has(column) ? { backgroundColor: backgroundColors.get(column) } : {}),
                                            }}
                                            onMouseEnter={() => {
                                                this.setState({
                                                    tableTooltip: {
                                                        rowValue: row[columns[0]],
                                                        colValue: column,
                                                        cellValue: row[column]
                                                    }
                                                });
                                            }}
                                            onMouseLeave={() => {
                                                this.setState({ tableTooltip: undefined });
                                            }}
                                        >
                                            {row[column]?.toLocaleString()}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        )
    }

    render(): React.ReactNode {
        const data = this.props.tableData.data;

        if (!data)
            return (
                <div>
                    Data not available
                </div>
            )

        const containerStyle = this.props.containerStyle || {};

        return (
            <Paper style={containerStyle}>
                <Tooltip open={!!this.state.tableTooltip}
                    onMouseMove={e => this.setState({
                        tooltipPosition: {
                            x: e.pageX - window.pageXOffset,
                            y: e.pageY - window.pageYOffset
                        }
                    })}
                    title={
                        <Typography>
                            <span>Row: {this.state.tableTooltip?.rowValue}</span>
                            <br />
                            <span>Column: {this.state.tableTooltip?.colValue}</span>
                            <br />
                            <span>Value: {this.state.tableTooltip?.cellValue}</span>
                        </Typography>
                    }
                    PopperProps={{
                        disablePortal: true,
                        anchorEl: {
                            clientHeight: 0,
                            clientWidth: 0,
                            getBoundingClientRect: () => ({
                                top: this.state.tooltipPosition?.y || 0,
                                left: this.state.tooltipPosition?.x || 0,
                                right: this.state.tooltipPosition?.x || 0,
                                bottom: this.state.tooltipPosition?.y || 0,
                                width: 0,
                                height: 0,
                                x: 0,
                                y: 0,
                                toJSON: () => ""
                            })
                        }
                    }}>
                    {this.getTable(data)}
                </Tooltip>

            </Paper>
        )
    }
}

export default VTable;
