import React from "react";
import {IDailyStrategyPNLReport} from "../../../apis/vitusApiTypes";
import VPageFilter from "../../../components/VPageFilter/VPageFilter";
import {FormControlLabel, Grid} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import VSelect from "../../../components/VSelect/VSelect";
import {IFilter} from "./DailyStrategyPNLReport";

interface FilterSelectProps {
    title: string;
    value: string | boolean | undefined;
    options: (string | number)[];
    onChange: (newValue: {} | string | null) => void;
}

export default function FilterSection({data, selectedFilter, onFilterChange}: {
    data: IDailyStrategyPNLReport[];
    selectedFilter: IFilter;
    onFilterChange: (filter: IFilter) => void;
}) {
    const filters = [
        {title: 'User', key: 'User' as keyof IFilter},
        {title: 'Exchange', key: 'Exchange' as keyof IFilter},
        {title: 'Symbol ID', key: 'SymbolId' as keyof IFilter}
    ];

    const handleFilterChange = (key: keyof IFilter, value: string) => {
        onFilterChange({
            ...selectedFilter,
            [key]: value
        });
    };

    const handleFeesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onFilterChange({
            ...selectedFilter,
            feesIncluded: event.target.checked
        });
    };

    return (
        <VPageFilter initialShow>
            <Grid container justify="center" alignItems="center">
                {filters.map(({title, key}) => (
                    <FilterSelect
                        key={key}
                        title={title}
                        value={selectedFilter[key]}
                        options={Array.from(new Set(data.map(report => report[key as keyof IDailyStrategyPNLReport])))}
                        onChange={(value) => handleFilterChange(key, value as string)}
                    />
                ))}
                <FormControlLabel
                    label="Fees Included"
                    control={
                        <Checkbox
                            checked={selectedFilter.feesIncluded}
                            onChange={handleFeesChange}
                            style={{marginLeft: 10}}
                        />
                    }
                />
            </Grid>
        </VPageFilter>
    );
};

function FilterSelect(props: FilterSelectProps) {
    const {
        title,
        value,
        options,
        onChange
    } = props;

    return <Grid item xs={6} sm={2} style={{padding: 10}}>
        <VSelect
            title={title}
            options={options}
            value={value || ''}
            getOptionLabel={(option) => option.toString()}
            onChange={onChange}
            width="100%"
        />
    </Grid>
}
