import React, {useMemo, useState} from "react";
import {IDailyStrategyPNLReportWithChanges} from "./DailyStrategyPNLReport";
import {formatDate, getActiveContractsMap, groupDailyPNLByDate} from "./utils";
import _ from "lodash";
import {Container, FormControlLabel, Grid} from "@material-ui/core";
import LastDaySummaryCard from "./LastDaySummaryCard";
import {makeStyles, Theme} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import DailyChart from "./DailyChart";

interface DailyStrategyPNLDashboardProps {
    data: IDailyStrategyPNLReportWithChanges[];
    onContractClick: (contract: IDailyStrategyPNLReportWithChanges) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    gridItem: {
        display: 'flex'
    },
    chartGridItem: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1), // 8px
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(2.5), // 20px
        }
    }
}));

const SummaryTab: React.FC<DailyStrategyPNLDashboardProps> = ({data, onContractClick}) => {
    const classes = useStyles();
    const [showInactives, setShowInactives] = useState(false);
    const [feesIncluded, setFeesIncluded] = useState(false);

    const activeContractsMap = getActiveContractsMap(data);

    const latestEntries = useMemo(() => {
        const grouped = _.groupBy(data, 'SymbolId')
        return Object.values(grouped).map(group => _.maxBy(group, entry => new Date(entry.Date)))
    }, [data]);

    const overallData = useMemo(() => groupDailyPNLByDate(data), [data]);

    console.log('overall data', overallData)

    return <Container maxWidth="xl">
        <FormControlLabel
            label="Show Expired Contracts"
            control={
                <Checkbox
                    checked={showInactives}
                    onChange={(event) => setShowInactives(event.target.checked)}
                />
            }
        />
        <FormControlLabel
            label="Fees Included"
            control={
                <Checkbox
                    checked={feesIncluded}
                    onChange={(event) => setFeesIncluded(event.target.checked)}
                />
            }
        />
        {
            overallData.length ?
                <Grid container>
                    <Grid item xs={12} md={9} className={classes.chartGridItem}>
                        <DailyChart data={overallData}
                                    symbol={`Profit and Loss - ${formatDate(new Date(overallData[0].Date))}`}
                                    feesIncluded={feesIncluded}/>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
                        <LastDaySummaryCard maxDateData={overallData[0]} symbol="Overall PnL"/>
                    </Grid>
                </Grid>
                : null
        }
        <Grid container spacing={3} style={{marginTop: 20}}>
            {latestEntries
                .filter(entry => showInactives || activeContractsMap.get(entry!.SymbolId))
                .map((entry) => (
                    <Grid item xs={12} sm={6} md={3} key={entry!.SymbolId} className={classes.gridItem}>
                        <LastDaySummaryCard
                            maxDateData={entry!}
                            symbol={entry!.SymbolId}
                            showDate={false}
                            showLastDate
                            expired={!activeContractsMap.get(entry!.SymbolId)}
                            onClick={onContractClick}
                        />
                    </Grid>
                ))}
        </Grid>
    </Container>
}

export default SummaryTab;
