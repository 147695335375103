import PowerSupplyDemand from "../views/Reports/PowerSupplyDemand/PowerSupplyDemand";
import CustomChart from "../views/CustomChart/CustomChart";
import { ReportView } from "./ReportBase";
import CustomChartsList from "../views/CustomChartsList/CustomChartsList";
import CustomReport from "../views/CustomReport/CustomReport";
import CreateDataset from "../views/Administration/CreateDataset/CreateDataset";
import DatasetList from "../views/Administration/DatasetList/DatasetList";
import ReportPermissions from "../views/Administration/ReportPermissions/ReportPermissions";
import CacheDashboard from "../views/Administration/CacheDashboard/CacheDashboard";
import UserManagement from "../views/Administration/UserManagement/UserManagement";
import GasBalanceReport from "../views/Reports/GasBalanceReport/GasBalanceReport";
import ForecastReport from "../views/Reports/ForecastReport/ForecastReport";
import MonthlyEuropeSummary from "../views/Reports/MonthlyEuropeSummary/MonthlyEuropeSummary";
import LongTermEuropeGasBalanceReport from "../views/Reports/LongTermEuropeGasBalanceReport/LongTermEuropeGasBalanceReport";
import WorkflowDashboard from "../views/Administration/WorkflowDashboard/WorkflowDashboard";
import PriceSupplyDemandComparisonReport from "../views/Reports/PriceSupplyDemandComparisonReport/PriceSupplyDemandComparisonReport";
import FtrAnalysesPnLReport from "../views/Reports/FtrAnalysesPnLReport/FtrAnalysesPnLReport";
import ContractPrices from "../views/Reports/ContractPrices/ContractPrices";
import PredictionReport from "../views/Reports/PredictionReport/PredictionReport";
import DailyStrategyPNLReport from "../views/Reports/DailyStrategyPNLReport/DailyStrategyPNLReport";

const reportViews = [
    PowerSupplyDemand,
    CustomChart,
    CustomChartsList,
    CustomReport,
    CreateDataset,
    DatasetList,
    ReportPermissions,
    CacheDashboard,
    UserManagement,
    GasBalanceReport,
    ForecastReport,
    MonthlyEuropeSummary,
    LongTermEuropeGasBalanceReport,
    WorkflowDashboard,
    PriceSupplyDemandComparisonReport,
    FtrAnalysesPnLReport,
    ContractPrices,
    PredictionReport,
    DailyStrategyPNLReport
]

class AuthManager {
    static reports: { [key: string]: typeof ReportView } = reportViews.reduce(
        (dict: { [key: string]: typeof ReportView }, report: typeof ReportView) => {
            dict[report.params.reportKey] = report; return dict;
        }, {})

    public static getReports() {
        return this.reports;
    }
}

export default AuthManager;