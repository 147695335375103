import {IDailyStrategyPNLReport} from "../../../apis/vitusApiTypes";
import {formatCurrencyValue, formatPercentageValue} from "./utils";
import VTable from "../../../components/VTable/VTable";
import React from "react";
import {IDailyStrategyPNLReportWithChanges} from "./DailyStrategyPNLReport";

interface DailyPNLTableProps {
    data: IDailyStrategyPNLReportWithChanges[];
    feesIncluded?: boolean;
}

type Column<T> = {
    key: keyof T;
    label: string;
    format?: (value: any) => any;
    backgroundColor?: string;
};

const COLORS = {
    DATE_EXCHANGE: '#FFFDE7',   // Yellow-ish
    PNL: '#F1F8E9',             // Light green
    PRICE: '#E3F2FD',           // Light blue
    DAILY_METRICS: '#FFF3E0',   // Light orange
    TOTAL_METRICS: '#FFEBEE'    // Light red
} as const;

export default function DailyPNLTable(props: DailyPNLTableProps) {
    const {data, feesIncluded = false} = props;


    const columns = getColumns(feesIncluded);
    const backgroundColors = new Map(
        columns.map(col => [col.label, col.backgroundColor || 'inherit'])
    );
    const tableData = formatTableData(data, columns);

    return <VTable
        defaultInitialRowCount={30}
        striped
        tableData={{
            columns: columns.map(col => col.label),
            data: tableData
        }}
        cellStyle={{padding: 4, textAlign: 'center', fontSize: 13}}
        keyFields={["Date", "Exchange", "SymbolId", "User"]}
        borderedColumns={["User", "PnL Carry Daily", "PnL Carry Daily (Net)", "Settlement Price", "SWAP Daily"]}
        backgroundColors={backgroundColors}
    />;
}

const formatTableData = (data: IDailyStrategyPNLReportWithChanges[], columns: Array<Column<IDailyStrategyPNLReportWithChanges>>) => {
    return data.map((row) => {
        return columns.reduce((acc, col) => {
            acc[col.label] = col.format ? col.format(row[col.key]) : row[col.key];
            return acc;
        }, {} as Record<string, string | number>);
    });
}

const createBasicColumns = (): Array<Column<IDailyStrategyPNLReport>> => [
    {
        key: "Date",
        label: "Date",
        format: (value) => new Date(value).toLocaleDateString(),
        backgroundColor: COLORS.DATE_EXCHANGE
    },
    {
        key: "Exchange",
        label: "Exchange",
        backgroundColor: COLORS.DATE_EXCHANGE
    },
    {
        key: "SymbolId",
        label: "SymbolId",
        backgroundColor: COLORS.DATE_EXCHANGE
    },
    {
        key: "User",
        label: "User",
        backgroundColor: COLORS.DATE_EXCHANGE
    }
];

const createPnLColumns = (feesIncluded: boolean): Array<Column<IDailyStrategyPNLReportWithChanges>> => [
    {
        key: feesIncluded ? "PnLTotalNet" : "PnLTotal",
        label: feesIncluded ? "PnL Total (Net)" : "PnL Total",
        format: formatCurrencyValue,
        backgroundColor: COLORS.PNL
    },
    {
        key: feesIncluded ? "PnlNetDailyChangePercent" : "PnlDailyChangePercent",
        label: feesIncluded ? "PnL Total Change % (Net)" : "PnL Total Change %",
        format: formatPercentageValue,
        backgroundColor: COLORS.PNL
    },
    {
        key: feesIncluded ? "PnLDailyNet" : "PnLDaily",
        label: feesIncluded ? "PnL Daily (Net)" : "PnL Daily",
        format: formatCurrencyValue,
        backgroundColor: COLORS.PNL
    },
    {
        key: feesIncluded ? "PnLCarryDailyNet" : "PnlCarryDaily",
        label: feesIncluded ? "PnL Carry Daily (Net)" : "PnL Carry Daily",
        format: formatCurrencyValue,
        backgroundColor: COLORS.PNL
    }
];

const createPriceColumns = (): Array<Column<IDailyStrategyPNLReport>> => [
    {
        key: "SettlementPrice",
        label: "Settlement Price",
        format: formatCurrencyValue,
        backgroundColor: COLORS.PRICE
    }
];

const createDailyMetricsColumns = (): Array<Column<IDailyStrategyPNLReport>> => [
    {
        key: "LongDaily",
        label: "Long Daily",
        backgroundColor: COLORS.DAILY_METRICS
    },
    {
        key: "ShortDaily",
        label: "Short Daily",
        backgroundColor: COLORS.DAILY_METRICS
    },
    {
        key: "BWAPDaily",
        label: "BWAP Daily",
        backgroundColor: COLORS.DAILY_METRICS
    },
    {
        key: "SWAPDaily",
        label: "SWAP Daily",
        backgroundColor: COLORS.DAILY_METRICS
    }
];

const createTotalMetricsColumns = (): Array<Column<IDailyStrategyPNLReport>> => [
    {
        key: "LongTotal",
        label: "Long Total",
        backgroundColor: COLORS.TOTAL_METRICS
    },
    {
        key: "ShortTotal",
        label: "Short Total",
        backgroundColor: COLORS.TOTAL_METRICS
    },
    {
        key: "BWAPTotal",
        label: "BWAP Total",
        format: formatCurrencyValue,
        backgroundColor: COLORS.TOTAL_METRICS
    },
    {
        key: "SWAPTotal",
        label: "SWAP Total",
        format: formatCurrencyValue,
        backgroundColor: COLORS.TOTAL_METRICS
    }
];

const getColumns = (feesIncluded: boolean = false): Array<Column<IDailyStrategyPNLReportWithChanges>> => {
    return [
        ...createBasicColumns(),
        ...createPnLColumns(feesIncluded),
        ...createPriceColumns(),
        ...createDailyMetricsColumns(),
        ...createTotalMetricsColumns()
    ];
};
