import {Button} from "@material-ui/core";
import React from "react";

export default function DownloadCSV({data, fileName}: { data: Record<string, any>[], fileName: string }) {
    return <Button variant="outlined" onClick={() => downloadCSV(data, fileName)}>Download CSV</Button>
}

const convertToCSV = (objArray: Record<string, any>[]) => {
    if (objArray.length === 0) return '';

    // Get headers from the first object
    const headers = Object.keys(objArray[0]);
    let str = headers.join(',') + '\r\n';

    // Add rows
    for (let i = 0; i < objArray.length; i++) {
        const line = headers.map(header => objArray[i][header]).join(',');
        str += line + '\r\n';
    }

    return str;
};

const downloadCSV = (data: Record<string, any>[], fileName: string) => {
    const csvData = new Blob([convertToCSV(data)], {type: 'text/csv'});
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
